/**
 * Syntax highlighting styles
 */
.highlight
{
	background: #1e1e1e;
	@extend %vertical-rhythm;

	.highlighter-rouge &
	{
		background: #1e1e1e;
	}

	.c     { color: #608b4e; } // Comment
	.cm    { color: #608b4e; } // Comment.Multiline
	.cp    { color: #608b4e; } // Comment.Preproc
	.c1    { color: #608b4e; } // Comment.Single
	.cs    { color: #608b4e; font-weight: bold; font-style: italic } // Comment.Special
	
	.o     { color: #b4b4b4; } // Operator
	.ow    { font-weight: bold } // Operator.Word
	
	.err   { color: #a00; } // Error
	.gd    { color: #000; background-color: #fdd } // Generic.Deleted
	.gd .x { color: #000; background-color: #faa } // Generic.Deleted.Specific
	.ge    { font-style: italic } // Generic.Emph
	.gr    { color: #a00 } // Generic.Error
	.gh    { color: #999 } // Generic.Heading
	.gi    { color: #000; background-color: #dfd } // Generic.Inserted
	.gi .x { color: #000; background-color: #afa } // Generic.Inserted.Specific
	.go    { color: #888 } // Generic.Output
	.gp    { color: #555 } // Generic.Prompt
	.gs    { font-weight: bold } // Generic.Strong
	.gu    { color: #aaa } // Generic.Subheading
	.gt    { color: #a00 } // Generic.Traceback
	.w     { color: #bbb } // Text.Whitespace

	.na    { color: #008080 } // Name.Attribute
	.nb    { color: #0086B3 } // Name.Builtin
	.nc    { color: #4ec9b0; font-weight: bold } // Name.Class
	.no    { color: #008080 } // Name.Constant
	.ni    { color: #800080 } // Name.Entity
	.ne    { color: #4ec9b0; } // Name.Exception
	.nf    { color: #dcdcdc; } // Name.Function
	.nn    { color: #dcdcdc } // Name.Namespace
	.nt    { color: #d7ba7d } // Name.Tag
	.nv    { color: #008080 } // Name.Variable

	.k     { color: #569cd6; } // Keyword
	.kc    { color: #569cd6; } // Keyword.Constant
	.kd    { color: #569cd6; } // Keyword.Declaration
	.kp    { color: #569cd6; } // Keyword.Pseudo
	.kr    { color: #569cd6; } // Keyword.Reserved
	.kt    { color: #569cd6; } // Keyword.Type

	.m     { color: #b5cea8 } // Literal.Number
	.mf    { color: #b5cea8 } // Literal.Number.Float
	.mh    { color: #b5cea8 } // Literal.Number.Hex
	.mi    { color: #b5cea8 } // Literal.Number.Integer
	.mo    { color: #b5cea8 } // Literal.Number.Oct

	.s     { color: #d69d85 } // Literal.String
	.sb    { color: #d69d85 } // Literal.String.Backtick
	.sc    { color: #d69d85 } // Literal.String.Char
	.sd    { color: #d69d85 } // Literal.String.Doc
	.s2    { color: #d69d85 } // Literal.String.Double
	.se    { color: #d69d85 } // Literal.String.Escape
	.sh    { color: #d69d85 } // Literal.String.Heredoc
	.si    { color: #d69d85 } // Literal.String.Interpol
	.sx    { color: #d69d85 } // Literal.String.Other
	.s1    { color: #d69d85 } // Literal.String.Single
	.sr    { color: #009926 } // Literal.String.Regex
	.ss    { color: #990073 } // Literal.String.Symbol

	.bp    { color: #999 } // Name.Builtin.Pseudo
	.vc    { color: #008080 } // Name.Variable.Class
	.vg    { color: #008080 } // Name.Variable.Global
	.vi    { color: #008080 } // Name.Variable.Instance
	.il    { color: #099 } // Literal.Number.Integer.Long
}
