@charset "utf-8";

// Define defaults for each variable.

$base-font-family:  "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$base-font-size:    16px !default;
$base-font-weight:  400 !default;
$small-font-size:   $base-font-size * 0.875 !default;
$base-line-height:  1.5 !default;

$spacing-unit:      30px !default;

$background-color:  #111 !default;
$header-bg-color:   #222 !default;

$text-color:        #d0d0d0 !default;
$text-color-light:  #f1f1f1 !default;
$text-color-dark:   #999999 !default;

$brand-color:       #84ceff !default;
$brand-color-dark:  #007fee !default;
//$brand-color-light: #1c97ea !default;

$grey-color:        #252526 !default;
$grey-color-light:  #3f3f46 !default;
$grey-color-dark:   #1e1e1e !default;

// Width of the content area
$content-width:     800px !default;

$on-palm:           600px !default;
$on-laptop:         800px !default;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
@mixin media-query($device)
{
	@media screen and (max-width: $device)
	{
		@content;
	}
}

@mixin relative-font-size($ratio)
{
	font-size: $base-font-size * $ratio;
}

// Import partials.
@import
	"minima/base",
	"minima/layout",
	"minima/syntax-highlighting"
;
