/**
 * Reset some basic elements
 */
body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure
{
	margin: 0;
	padding: 0;
}



/**
 * Basic styling
 */
body
{
	font: $base-font-weight #{$base-font-size}/#{$base-line-height} $base-font-family;
	color: $text-color;
	background-color: $background-color;
	-webkit-text-size-adjust: 100%;
	-webkit-font-feature-settings: "kern" 1;
	   -moz-font-feature-settings: "kern" 1;
	     -o-font-feature-settings: "kern" 1;
	        font-feature-settings: "kern" 1;
	font-kerning: normal;
}



/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
ul, ol, dl, figure,
%vertical-rhythm
{
	margin-bottom: $spacing-unit / 2;
}


/**
 * Images
 */
img
{
	max-width: 100%;
	vertical-align: middle;
}


/**
 * Figures
 */
figure > img
{
	display: block;
}

figcaption
{
	font-size: $small-font-size;
}


/**
 * Lists
 */
ul, ol
{
	margin-left: $spacing-unit;
}

li
{
	> ul,
	> ol
	{
		margin-bottom: 0;
	}
}


/**
 * Headings
 */
h1, h2, h3, h4, h5, h6
{
	font-weight: $base-font-weight;
	color: $text-color-light;
}



/**
 * Links
 */
a
{
	color: $brand-color;
	text-decoration: none;

	&:visited
	{
		color: $brand-color;
	}

	&:hover
	{
		text-decoration: underline;
	}

	.social-media-list &:hover
	{
		text-decoration: none;

		.username
		{
			text-decoration: underline;
		}
	}
}


/**
 * Blockquotes
 */
blockquote
{
	color: $grey-color;
	border-left: 4px solid $grey-color-light;
	padding-left: $spacing-unit / 2;
	@include relative-font-size(1.125);
	letter-spacing: -1px;
	font-style: italic;

	> :last-child
	{
		margin-bottom: 0;
	}
}



/**
 * Code formatting
 */
pre,
code 
{
	@include relative-font-size(0.9375);
	border: 1px solid $grey-color-light;
	background-color: $grey-color-dark;
}

code
{
	padding: 1px 5px;
}

pre
{
	padding: 8px 12px;
	overflow-x: auto;

	> code
	{
		border: 0;
		padding-right: 0;
		padding-left: 0;
	}
}



/**
 * Wrapper
 */
.wrapper
{
	max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit} * 2));
	max-width:         calc(#{$content-width} - (#{$spacing-unit} * 2));
	margin-right: auto;
	margin-left: auto;
	padding-right: $spacing-unit;
	padding-left: $spacing-unit;
	@extend %clearfix;

	@include media-query($on-laptop)
	{
		max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit}));
		max-width:         calc(#{$content-width} - (#{$spacing-unit}));
		padding-right: $spacing-unit / 2;
		padding-left: $spacing-unit / 2;
	}
}



/**
 * Clearfix
 */
%clearfix:after
{
	content: "";
	display: table;
	clear: both;
}



/**
 * Icons
 */
.icon > svg
{
	display: inline-block;
	vertical-align: middle;

	path
	{
		fill: $grey-color-light;
	}
}

.svg-text
{
	display: inline-block;
	vertical-align: middle;
}

.social-media-list 
{
	.icon
	{
		padding-right: 5px;
	}

	li + li
	{
		padding-top: 5px;
	}
}
